.funnel {
  margin-bottom: 120px;

  &__nav {
    @include media-breakpoint-up(lg) {
      max-width: 960px;
    }

    .step {
      background: none;
      border: none;
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;
      color: rgba($color-dark, 20%);

      span {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        background-color: rgba(#50A5A0, 10%);
        bottom: 0;
        height: 2px;
        left: 0;
        right: 0;
      }

      &.done {
        &::after {
          background: $gradient;
        }
      }

      &.active {
        color: $color-dark;

        &::after {
          background: $gradient;
        }
      }
    }
  }

  &__content {
    @include media-breakpoint-up(lg) {
      max-width: 960px;
    }

    form {
      > div {
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        input {
          display: block;
          width: 100%;
          padding: 14px 22px;
          font-size: 16px;
          line-height: 1.5;
          color: $color-dark;
          background-color:$color-light;
          border: none;
          border-radius: .25rem;
        }
      }

      .btn {
        float: right;
        margin: 12px 0;
      }
    }

    .phase {
      &__title {
        margin-top: 42px;
        margin-bottom: 42px;
        font-weight: bold;
        font-size: 48px;
        line-height: 1;

        @include media-breakpoint-up(md) {
          text-align: left;
          margin-top: 64px;
          margin-bottom: 42px;
        }

        @include media-breakpoint-up(lg) {
          margin-top: 75px;
          margin-bottom: 64px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 80px;
        }
      }

      .text {
        margin-bottom: 42px;

        @include media-breakpoint-up(md) {
          margin-bottom: 42px;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: 64px;
        }
      }

      .codes {
        .slides {
          .slide:not(.active) { display: none; }

          text-align: center;
          margin-top: 64px;
          margin-bottom: 32px;

          @include media-breakpoint-up(md) {
            margin-top: 120px;
          }

          .info {
            max-width: 196px;
            margin: 0 auto 32px;
            font-weight: bold;

            @include media-breakpoint-up(md) {
              margin-bottom: 42px;
            }
          }

          .code {
            margin-bottom: 32px;
            position: relative;
            font-size: 0;

            @include media-breakpoint-up(md) {
              margin-bottom: 42px;
            }

            input {
              width: calc((100% - 5 * 4px) / 6);
              max-width: 56px;
              text-align: center;
              border: none;
              background-color: $color-light;
              color: $color-dark;
              font-family: $font-family-monospace;
              border: 1px solid $color-light;
              border-radius: 10px;
              outline: none;
              font-size: 16px;
              padding: 10px;
              line-height: 1;

              &:not(:last-of-type) {
                margin-right: 4px;
              }

              -moz-appearance: textfield;
              appearance: textfield;
              margin: 0;
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              &:focus {
                border-color: rgba(#40619D, 10%);
              }

              @include media-breakpoint-up(md) {
                width: calc((100% - 5 * 8px) / 6);
                max-width: 75px;
                font-size: 20px;
                padding: 20px;
              }
            }
          }

          .send-new-code {
            p {
              max-width: 196px;
              margin: 0 auto 24px;
            }
          }
        }

        .steps {
          .step div {
            background-color: rgba(#50A5A0, 10%);
            height: 2px;
          }

          .active div {
            background: $gradient;
          }
        }

        .plus {
          max-width: 300px;
          text-align: center;
          margin: 28px auto 0;
        }
      }

      .investment-selector {
        .card {
          background-color: $color-light;
          border-radius: 10px;
          box-sizing: $shadow;
          overflow: hidden;
          transition: .2s cubic-bezier(0.075, 0.82, 0.165, 1);

          &:hover {
            position: relative;
            z-index: 1;
            box-shadow: $shadow;
            transform: scale(1.05);
          }

          &__image {
            width: 100%;
            height: calc(100vw / 2 - var(--bs-gutter-x));
            padding: var(--bs-gutter-x);

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              mix-blend-mode: multiply;
            }

            @each $breakpoint, $container-max-width in $container-max-widths {
              @include media-breakpoint-up($breakpoint) {
                height: calc(#{$container-max-width} / 2 - var(--bs-gutter-x));

                @include media-breakpoint-up(md) {
                  height: calc(#{$container-max-width} / 4 - var(--bs-gutter-x));
                }
              }
            }
          }

          &__content {
            background-color: $color-dark;
            color: #fff;
            padding: var(--bs-gutter-x);
            text-align: center;

            .euros {
              background-color: #fff2;
              border-radius: 100px;
              display: inline-block;
              line-height: 1;
              padding: 8px 20px;
              font-size: 12px;
            }

            .o21 {
              font-size: 20px;
            }

            .btn {
              box-shadow: none;
            }
          }
        }
      }

      .payment {
        .left {
          padding-top: 32px;
          margin-bottom: var(--bs-gutter-x);

          .tab-select {
            margin-bottom: 24px;

            button {
              border: none;
              background: none;
              padding: 0;
              margin-right: 32px;
              color: $color-dark;
              opacity: .2;

              &.active {
                opacity: 1;
                border-bottom: 1px solid $color-dark;
              }
            }
          }

          .tabs {
            > div:not(.active) {
              display: none;
            }

            .wire {
              .bank-info {
                .label {
                  font-size: 12px;
                  opacity: .3;
                  margin: 0;
                }

                .data {
                  margin-bottom: 12px;

                  &.mono {
                    font-family: $font-family-monospace;
                  }

                  &.red {
                    color: $color-red;
                  }

                  button {
                    padding: 0;
                    border: none;
                    background: none;
                    color: $color-dark;
                    opacity: .3;

                    span {
                      font-size: 1em;
                      vertical-align: -2px;
                    }
                  }
                }
              }
            }
          }
        }

        .right {
          background-color: $color-light;
          border-radius: 10px;
          padding: 32px var(--bs-gutter-x);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: var(--bs-gutter-x);

          .head {
            margin-bottom: 24px;
            font-weight: bold;
          }

          .summary {
            list-style: none;
            padding: 0;

            li {
              display: flex;
              justify-content: space-between;

              p {
                margin-bottom: 0;
              }
            }
          }

          .total {
            font-weight: bold;
            display: flex;
            justify-content: space-between;

            p {
              margin-bottom: 0;
            }
          }
        }

        .btn {
          float: right;
          margin: 0 calc(0px - var(--bs-gutter-x) / 2) 0 0;
        }
      }
    }
  }
}
