.hero-o21 {
  margin-top: 54px;

  &__text {
    h1 {
      font-weight: bold;
      text-align: center;
      font-size: 32px;
      line-height: 1;

      @include media-breakpoint-up(sm) {
        font-size: 48px;
      }

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-up(xl) {
        font-size: 80px;
      }
    }
  }

  &__picture {
    margin-top: -24px;
    position: relative;
    z-index: -1;

    @include media-breakpoint-up(md) {
      height: 70vh;
    }

    video, img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: $color-lighter;

      @include media-breakpoint-up(sm) {
        transform: scale(0.8);
      }
    }
  }
}
