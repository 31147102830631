.hero-obvious21 {
  margin-top: 54px;
  margin-bottom: 54px;

  @include media-breakpoint-up(md) {
    margin-bottom: 104px;
    margin-top: 84px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 164px;
  }

  &__text {
    h1 {
      font-weight: bold;
      text-align: center;
      font-size: 32px;
      line-height: 1;

      @include media-breakpoint-up(sm) {
        font-size: 48px;
      }

      // @include media-breakpoint-up(md) {
      //   text-align: left;
      // }

      @include media-breakpoint-up(xl) {
        font-size: 80px;
      }
    }
  }
}
