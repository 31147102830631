.timeout {
  padding-top: 100px;
  padding-bottom: 64px;

  @include media-breakpoint-up(md) {
    padding-top: 164px;
    padding-bottom: 124px;
  }

  &__name {
    font-family: $font-family-monospace;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
      margin-bottom: 92px;
    }
  }

  &__timer {
    margin-bottom: 48px;

    @include media-breakpoint-up(md) {
      margin-bottom: 84px;
    }

    .component {
      text-align: center;

      .number {
        font-family: $font-family-monospace;
        font-size: 32px;
        line-height: 1;
        margin-bottom: 7px;
        
        @include media-breakpoint-up(md) {
          font-size: 90px;
        }
      }

      .name {
        font-size: 12px;
        line-height: 1;
        opacity: 30%;
        margin: 0;
      }
    }
  }

  &__actions {
    text-align: center;

    .btn {
      margin: 0 8px 16px;
    }
  }
}
