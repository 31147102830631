.cards {
  padding-top: 60px;
  background-color: $color-light;

  @include media-breakpoint-up(md) {
    padding-top: 120px;
  }

  &__head {
    margin-bottom: 100px;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 1;

      @include media-breakpoint-up(sm) {
        font-size: 42px;
      }

      @include media-breakpoint-up(md) {
        font-size: 56px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 70px;
      }
    }
  }

  &__body {
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      padding-bottom: 100px;
    }

    .card {
      margin-bottom: 24px;
      min-height: 250px;
      background-color: $color-lighter;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 10px;
      padding: 16px;
      box-shadow: $shadow;

      p {
        font-size: 20px;
        line-height: 24px;

        em {
          font-style: normal;
          @include text-gradient;
        }
      }
    }

    @include media-breakpoint-only(xs) {
      li:nth-of-type(4n+1) {
        @include make-col-offset(1);
      }

      li:nth-of-type(2n+2) {
        @include make-col-offset(2);
      }

      li:nth-of-type(4n+3) {
        @include make-col-offset(3);
      }
    }

    @include media-breakpoint-only(sm) {
      li:nth-of-type(2n+1) {
        @include make-col-offset(1);
      }

      li:nth-of-type(2n) {
        transform: translateY(-32px);
      }
    }

    @include media-breakpoint-only(md) {
      li:nth-of-type(3n+1) {
        @include make-col-offset(1);
      }

      li:nth-of-type(3n+2) {
        transform: translateY(-32px);
      }

      li:nth-of-type(3n) {
        transform: translateY(32px);
      }
    }

    @include media-breakpoint-up(lg) {
      li:nth-of-type(5n+1) {
        @include make-col-offset(1);
      }

      li:nth-of-type(5n+2) {
        transform: translateY(32px);
      }

      li:nth-of-type(5n+3) {
        transform: translateY(-32px);
      }

      li:nth-of-type(5n+4) {
        transform: translateY(-48px);
      }

      li:nth-of-type(5n) {
        transform: translateY(-64px);
      }
    }
  }
}