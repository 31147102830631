.text-and-image {
  background-color: $color-light;
  padding-top: 48px;
  padding-bottom: 0;

  @include media-breakpoint-up(md) {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  &__text {
    text-align: center;
    margin-bottom: 48px;

    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
    }

    .name {
      font-family: $font-family-monospace;
      text-transform: uppercase;
      font-size: 12px;
      opacity: .4;
      margin-bottom: 54px;
    }

    .title {
      font-size: 32px;
      font-weight: bold;
      @include text-gradient;
      margin-bottom: 32px;

      @include media-breakpoint-up(md) {
        font-size: 42px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 64px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 80px;
      }
    }

    .paragraph {
      font-size: 16px;
      line-height: 1.375;
      margin-bottom: 32px;
    }

    .btn {
      margin: 0 0 0 -5px;
    }
  }

  &__image {
    position: relative;

    @include media-breakpoint-down(md) {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -50vw;
        height: 50%;
        width: 200vw;
        background-color: $color-lighter;
        z-index: 0;
      }
    }

    picture, img {
      position: relative;
      z-index: 1;
      border-radius: 20px;
      width: 100%;
      box-shadow: $shadow-large;
    }
  }
}
