.footer {
  background-color: $color-dark;
  border-top: 64px solid $color-light;

  &__content {
    background-color: $color-dark;
    color: #fff;
    padding-top: 16px;
    // box-shadow: 50vw 0 $color-light;

    // @include media-breakpoint-up(md) {
    //   .col-md-2 {
    //     width: calc(2 * 100% / 10);
    //   }

    //   .offset-md-1 {
    //     margin-left: calc(1 * 100% / 10);
    //   }
    // }

    .links {
      margin-bottom: 120px;

      .list {
        margin-top: 32px;

        &__head {
          font-size: 16px;
          opacity: 0.5;
        }

        &__links {
          font-size: 16px;
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          a {
            text-decoration: none;
          }
        }
      }

      .logo {
        margin-top: 32px;

        svg {
          path { fill: #fff !important; }
        }
      }
    }

    .copy {
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
}
