
.top-banner {
  background-color: $color-green;
  color: #fff;
  text-align: center;
  padding: 10px 0;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: $color-lighter;

  &.menu-open {
    .navbar__links {
      display: block;
    }
  }

  .navbar {
    padding-top: 14px;
    padding-bottom: 18px;

    .logo {
      svg {
        height: 24px;
      }
    }

    &__toggle {
      background: none;
      border: none;

      span {
        font-size: 20px;
        line-height: 24px;
        vertical-align: middle;
        color: $color-dark;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &__links {
      display: none;
      text-align: center;
      margin-top: 16px;

      a, span.alone {
        text-decoration: none;
        display: block;
        margin-bottom: 16px;
      }

      @include media-breakpoint-up(md) {
        display: block;
        margin-top: 0;

        a, span.alone {
          display: inline;

          &.alone {
            margin-left: 32px;
          }

          &:not(:last-of-type) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}
