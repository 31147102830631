.panels {
  &__left {
    background-color: $color-green;
    position: relative;
    overflow: hidden;

    svg {
      position: absolute;
      top: -25%;
      left: -25%;
      width: 100%;
      height: auto;
      z-index: 0;
      opacity: .1;
    }

    p {
      position: relative;
      z-index: 1;
      font-size: 32px;
      line-height: 1;
      color: #fff;
      font-weight: bold;
      margin-top: 54px;
      margin-bottom: 54px;

      @include media-breakpoint-up(sm) {
        font-size: 8vw;
      }

      @include media-breakpoint-up(md) {
        margin-top: 220px;
        margin-bottom: 220px;
        font-size: 5vw;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 200px;
        margin-bottom: 200px;
      }
    }
  }

  &__right {
    padding-top: 64px;
    padding-bottom: 96px;
    background-color: $color-light;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 140px;
      padding-bottom: 140px;
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 1;
      margin-bottom: 32px;

      @include media-breakpoint-up(md) {
        font-size: 42px;
      }
    }

    p {
      font-size: 16px;
      line-height: 1.375;
      margin-bottom: 26px;

      @include media-breakpoint-up(md) {
        margin-bottom: 56px;
      }
    }

    .btn {
      margin: 0;
    }
  }

  &__arrow {
    position: relative;
    z-index: 1;
    width: 36px;
    height: 36px;
    background-color: $color-dark;
    color: #fff;
    margin: -18px auto;
    text-align: center;
    border-radius: 18px;

    span {
      font-size: 20px;
      line-height: 1.8;
    }
  }
}
