@import '../../../node_modules/plyr/src/sass/plyr.scss';

.plyr {
  --plyr-color-main: #{$color-green};
}

.video {
  position: relative;
  padding-bottom: 60px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 84px;
  }

  &::after {
    content: '';
    position: absolute;
    background-color: $color-light;
    top: calc(12 * (100vw - 16px) / 12 * 9 / 16 / 2);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    @include media-breakpoint-up(sm) {
      top: calc(10 * (100vw - 16px) / 12 * 9 / 16 / 2);
    }

    @include media-breakpoint-up(lg) {
      top: calc(8 * (100vw - 16px) / 12 * 9 / 16 / 2);
    }

    @include media-breakpoint-up(xl) {
      top: calc(6 * (100vw - 16px) / 12 * 9 / 16 / 2);
    }
  }

  &__player {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 64px;
    }

    .plyr {
      border-radius: 10px;
    }
  }

  &__cta {
    text-align: center;
  }
}
