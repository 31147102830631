.call-to-action {
  padding-top: 100px;
  padding-bottom: 64px;
  
  @include media-breakpoint-up(md) {
    padding-bottom: 100px;
  }

  &__name {
    font-family: $font-family-monospace;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    margin-bottom: 32px;
  
    @include media-breakpoint-up(md) {
      margin-bottom: 62px;
    }
  }

  &__actions {
    text-align: center;

    .btn {
      margin: 0 8px 16px;
    }
  }
}
