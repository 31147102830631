.big-list {
  padding-top: 60px;

  @include media-breakpoint-up(md) {
    padding-top: 120px;
  }

  &__head {
    margin-bottom: 120px;

    @include media-breakpoint-up(md) {
      margin-bottom: 100px;
    }

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 1;

      @include media-breakpoint-up(sm) {
        font-size: 42px;
      }

      @include media-breakpoint-up(md) {
        font-size: 56px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 70px;
      }
    }
  }

  &__body {
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
      margin-bottom: 100px;
    }

    .item {
      margin-bottom: 120px;

      @include media-breakpoint-up(md) {
        margin-bottom: 60px;
      }

      > div {
        position: relative;
        max-width: 400px;
      }

      &__index {
        position: absolute;
        @include text-gradient;
        font-size: 136px;
        margin: 0;
        line-height: 1;
        top: calc(-136px / 2);
        font-weight: bold;
        z-index: -1;
        opacity: .5;

        @include media-breakpoint-up(md) {
          font-size: 20vw;
          top: -10vw;
        }
      }

      &:nth-of-type(2n) {
        > div {
          @include make-col-offset(1);

          @include media-breakpoint-up(lg) {
            @include make-col-offset(2);
          }
        }

        .item__index {
          right: -20px;

          @include media-breakpoint-up(md) {
            right: -10vw;
          }
        }
      }

      &:nth-of-type(2n+1) {
        > div {
          @include make-col-offset(2);

          @include media-breakpoint-up(sm) {
            @include make-col-offset(5);
          }

          @include media-breakpoint-up(md) {
            @include make-col-offset(7);
          }
        }

        .item__index {
          left: -20px;

          @include media-breakpoint-up(md) {
            left: -10vw;
          }
        }
      }

      &__title {
        font-size: 20px;
        font-weight: bold;

        @include media-breakpoint-up(md) {
          font-size: 28px;
        }
      }

      &__text {
        font-size: 14px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }
    }
  }
}