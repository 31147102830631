
@font-face {
  font-family: 'NB International Pro';
  src: url('./assets/fonts/NBInternationalPro-Bold.woff2') format('woff2'), url('./assets/fonts/NBInternationalPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NB International Pro Mono';
  src: url('./assets/fonts/NBInternationalPro-Mono.woff2') format('woff2'), url('./assets/fonts/NBInternationalPro-Mono.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NB International Pro';
  src: url('./assets/fonts/NBInternationalPro-Reg.woff2') format('woff2'), url('./assets/fonts/NBInternationalPro-Reg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'o21';
  src: url('./assets/fonts/o21.woff2') format('woff2'), url('./assets/fonts/o21.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font-family-sans-serif: 'NB International Pro', 'Helvetica', Arial, sans-serif, 'o21';
$font-family-monospace: 'NB International Pro Mono', monospace, 'o21';