@import './scss/type';
@import './scss/variables';
@import '../node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';

html {
  overflow-x: hidden;
}

a {
  color: currentColor !important;
}

@mixin text-gradient {
  background: $gradient;
  background-clip: text;
  text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

h1, h2 { em {
  font-style: normal;
  @include text-gradient;
}}

.btn {
  font-family: 'NB International Pro';
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 16px 32px;
  color: $color-dark !important;
  border-radius: 100px;
  background-color: $color-lighter;
  box-shadow: $shadow;
  cursor: pointer;
  font-weight: bold;
  margin: 10px;
  border: none;
  text-decoration: none;

  &--big {
    font-size: 20px;
    padding: 22px 42px;
  }

  &--big#{&}--icon {
    span {
      line-height: 20px;
      font-size: 26px;
      vertical-align: -6px;
    }
  }

  &--icon {
    color: $color-dark !important;

    span {
      line-height: 16px;
      font-size: 22px;
      vertical-align: -6px;
      color: $color-green;
      margin-right: 8px;
      margin-left: -8px;
    }
  }

  &--accent {
    color: #fff !important;
    background-color: $color-green;
  }

  &--dark {
    color: #fff !important;
    background-color: $color-dark;
  }

  &--special {
    color: #fff !important;
    background: $gradient;
  }

  &--accent#{&}--icon, &--special#{&}--icon {
    span {
      color: #fff;
      opacity: 0.75;
    }
  }
}

svg.logo {
  .leaf { fill: $color-green; }
  .o { fill: $color-dark; }
  .bvious { fill: $color-dark; }
  .txt { fill: $color-green; }
}

@import './scss/components/header';
@import './scss/components/hero-o21';
@import './scss/components/hero-obvious21';
@import './scss/components/panels';
@import './scss/components/timeout';
@import './scss/components/text-and-image';
@import './scss/components/call-to-action';
@import './scss/components/video';
@import './scss/components/big-list';
@import './scss/components/cards';
@import './scss/components/funnel';
@import './scss/components/footer';

.grid-helper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: 0.25;
  pointer-events: none;

  .row > div {
    height: 100vh;

    > div {
      height: 100vh;
      background-color: red;
    }
  }
}

main .gradient-transition {
  height: 300px;
  margin-bottom: -100px;
  background: linear-gradient(180deg, $color-light 0%, $color-lighter 100%);
}
